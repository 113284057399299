<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3 col-12">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-130 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchContracts('title')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchContracts('title')"
                    />
                    <v-select
                        :items="filterItems.status"
                        :loading="loading.filterItems.status"
                        :placeholder="$t('message.status')"
                        class="filter-width-130 ml-2"
                        dense
                        hide-details="auto"
                        item-text="Status.title"
                        item-value="Status.id"
                        solo
                        v-model="filterValues.status"
                        @change="filterContracts()"
                    >
                        <template v-slot:item="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                    </v-select>
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-140 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterContracts()"
                    />
                    <v-autocomplete
                        :items="filterItems.salesContact"
                        :loading="loading.filterItems.salesContact"
                        :placeholder="$t('message.salesColleague')"
                        class="filter-width-150 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Salescontact.title"
                        item-value="Salescontact.id"
                        solo
                        v-model="filterValues.salesContact"
                        @change="filterContracts()"
                    />
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.spl')"
                        class="filter-width-130 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterContracts()"
                    />
                    <v-autocomplete
                        :items="filterItems.destination"
                        :loading="loading.filterItems.destination"
                        :placeholder="$t('message.dest')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Country.name"
                        item-value="Country.id"
                        solo
                        v-model="filterValues.destination"
                        @change="filterContracts()"
                    />
                    <div class="col-12 height-10px pa-0" v-if="$vuetify.breakpoint.mdAndDown"/>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.osd"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('osd')"
                                :placeholder="$t('message.osd')"
                                append-icon="event"
                                :class="'force-text-left filter-width-185' + ($vuetify.breakpoint.lgAndUp ? ' ml-2' : '')"
                                clearable
                                hint="DD/MM/YY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('osd')"
                                @click:append="dateFilterMenus.osd = true"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.osd"
                            @input="closeDateFilterMenu('osd')"
                            @change="searchContracts('osd')"
                        ></v-date-picker>
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.ets"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('ets')"
                                :placeholder="$t('message.ets')"
                                append-icon="event"
                                class="force-text-left filter-width-170 ml-2"
                                clearable
                                hint="DD/MM/YY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('ets')"
                                @click:append="dateFilterMenus.ets = true"
                            />
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.ets"
                            @input="closeDateFilterMenu('ets')"
                            @change="searchContracts('ets')"
                        />
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.etd"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('etd')"
                                :placeholder="$t('message.etd')"
                                append-icon="event"
                                clearable
                                class="force-text-left filter-width-170 ml-2"
                                hint="DD/MM/YY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('etd')"
                                @click:append="dateFilterMenus.etd = true"
                            />
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.etd"
                            @input="closeDateFilterMenu('etd')"
                            @change="searchContracts('etd')"
                        />
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.eta"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('eta')"
                                :placeholder="$t('message.eta')"
                                append-icon="event"
                                clearable
                                class="force-text-left filter-width-170 ml-2"
                                hint="DD/MM/YY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('eta')"
                                @click:append="dateFilterMenus.eta = true"
                            />
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.eta"
                            @input="closeDateFilterMenu('eta')"
                            @change="searchContracts('eta')"
                        />
                    </v-menu>
                    <v-btn :loading="loading.clear" class="ml-2 px-2" @click="clearFilters">{{ $t('message.clear') }}</v-btn>
                </div>
            </v-row>
            <div class="font-sm grey--text">{{ $t('message.noteDateFiltersAreRanges') }}</div>
        </v-sheet>
        <v-overlay
            :value="loading.contracts"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="hideDefaultFooter"
            :items="Contracts"
            :options="tableOptions"
            :server-items-length="totalContracts"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            id="contractTable"
            item-key="Contract.id"
            @update:options="updateDataTable"
        >
            <template v-slot:top>
                <div class="d-flex flex-row pt-0 pb-2 align-center">
                    <div class="font-sm">
                        <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.open') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="orange lighten-3">fiber_manual_record</v-icon>{{ $t('message.completed') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="pink lighten-3">fiber_manual_record</v-icon>{{ $t('message.unsigned') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="blue darken-4">fiber_manual_record</v-icon>{{ $t('message.poUnsigned') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="yellow lighten-3">fiber_manual_record</v-icon>{{ $t('message.whPoUnsigned') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="purple lighten-1">fiber_manual_record</v-icon>{{ $t('message.cancelled') }}
                    </div>
                    <v-spacer/>
                    <div class="mb-2 mt-0 pb-0 pt-0">
                        <ExportTableContract
                            :export-conditions="exportConditions"
                            :export-data="Contracts"
                            :export-fields="headers"
                            class="ml-3 mt-0 pb-0"
                        />
                    </div>
                </div>
            </template>
            <template v-slot:item.Contract.title="{ item }">
                <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                <v-icon small color="black lighten-1" class="ml-2" v-if="item.Contract.issplitcomplete == 1">fas fa-code-branch</v-icon>
            </template>
            <template v-slot:item.Contract.salestatus_id="{ item }">
                <div class="text-center">
                    <v-icon small color="green lighten-1" v-if="item.Contract.salestatus_id == 1">fiber_manual_record</v-icon>
                    <v-icon small color="orange lighten-3" v-if="item.Contract.salestatus_id == 2">fiber_manual_record</v-icon>
                    <v-icon small color="purple lighten-1" v-if="item.Contract.salestatus_id == 10">fiber_manual_record</v-icon>
                    <v-icon small color="pink lighten-3" v-if="getStatus(item) == 'contractUnsigned'">fiber_manual_record</v-icon>
                    <v-icon small color="blue darken-4" v-if="getStatus(item) == 'poUnsigned'">fiber_manual_record</v-icon>
                    <v-icon small color="yellow lighten-3" v-if="getStatus(item) == 'whPoUnsigned'">fiber_manual_record</v-icon>
                    <v-icon small color="red" v-if="[1,2,10].includes(item.Contract.salestatus_id) == false">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Contract.contractdate="{ item }">
                {{ item.Contract.contractdate != '0000-00-00' ? formatDate(item.Contract.contractdate) : '' }}
            </template>
            <template v-slot:item.Contract.Customer.title="{ item }">
                {{ item.Contract.Customer.otsname != '' ? item.Contract.Customer.otsname : item.Contract.Customer.title }}
            </template>
            <template v-slot:item.Contract.Salesperson.abbreviation="{item}">
                <span v-if="[1,2,5,6].includes(item.Contract.salestype_id)">{{ item.Contract.Salesperson.abbreviation }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Contract.Supplier.code="{ item }">
                <span v-if="[1,5].includes(item.Contract.salestype_id)">APP</span>
                <span v-else-if="[2,3,6].includes(item.Contract.salestype_id)">{{ item.Contract.Supplier.code != '' ? item.Contract.Supplier.code : item.Contract.Supplier.title }}</span>
            </template>
            <template v-slot:item.Contract.po="{ item }">
                <span v-if="[1,5].includes(item.Contract.salestype_id)">{{ item.Contract.Supplier.code != '' ? item.Contract.Supplier.code : item.Contract.Supplier.title }}</span>
                <span v-else-if="item.Contract.salestype_id == 2">OK</span>
            </template>
            <template v-slot:item.Contract.species="{ item }">
                <div class="text-no-wrap truncate" v-if="item.Contract.version == null">{{ item.Contract.species }}</div>
                <div class="d-flex flex-column" v-if="item.Contract.version != null">
                    <div :class="JSON.parse(item.Contract.species_ids).length > 1 ? 'font-xs ' : ''" v-for="species in contractSpecies(JSON.parse(item.Contract.species_ids)) ">{{ species }}</div>
                    <div v-if="item.Contract.species_ids == null">{{ item.Contract.species }}</div>
                </div>
            </template>
            <template v-slot:item.Contract.grade="{ item }">
                <div class="text-no-wrap truncate" v-if="item.Contract.version == null">{{ item.Contract.grade }}</div>
                <div class="d-flex flex-column" v-if="item.Contract.version != null">
                    <div :class="(JSON.parse(item.Contract.grade_ids).length > 1 ? 'font-xs ' : '') + 'text-no-wrap truncate'" v-for="grade in contractGrades(JSON.parse(item.Contract.grade_ids)) ">{{ grade }}</div>
                    <div v-if="item.Contract.grade_ids == null">{{ item.Contract.grade }}</div>
                </div>
            </template>
            <template v-slot:item.Contract.size="{ item }">
                <div class="text-no-wrap truncate" v-if="item.Contract.version == null">{{ item.Contract.size }}</div>
                <div class="d-flex flex-column" v-if="item.Contract.version != null">
                    <div :class="(JSON.parse(item.Contract.size_ids).length > 1 ? 'font-xs ' : '') + 'text-no-wrap truncate'" v-for="size in contractSizes(JSON.parse(item.Contract.size_ids))">{{ size }}</div>
                    <div v-if="item.Contract.size_ids == null">{{ item.Contract.size }}</div>
                </div>
            </template>
            <template v-slot:item.Contract.containersize_id="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Contract.containersize_id) ">{{ item.Contract.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Contract.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Contract.containersize_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Contract.cost="{ item }">
                <div class="flex-row">
                    <span class="pr-1">{{ currency(item.Contract.currency_id) }}</span>
                    <span class="text-right">{{ formatThisNumber(item.Contract.cost) }}</span>
                </div>
            </template>
            <template v-slot:item.Contract.amount="{ item }">
                <div class="flex-row text-left">
                    <span class="pr-1">{{ currency(item.Contract.currency_id) }}</span>
                    <span class="text-right">{{ formatThisNumber(item.Contract.amount) }}</span>
                </div>
            </template>
            <template v-slot:item.Contract.shipweekdate="{ item }">
                {{ item.Contract.shipweekdate != '0000-00-00' ? formatDate(item.Contract.shipweekdate) : '' }}
            </template>
            <template v-slot:item.Contract.shipmentstatus_id="{ item }">
                <span class="text-no-wrap" v-if="item.Contract.shipmentstatus_id == 6">
                    <v-icon x-small color="grey" class="mr-1">fas fa-ship</v-icon><span :class="item.Contract.shipmentstatus_text && item.Contract.shipmentstatus_text.length > 10 ? 'font-xs' : ''">{{ getShipmentStatus(item.Contract.shipmentstatus_id, item.Contract.shipmentstatus_text) }}</span>
                </span>
                <span v-else class="text-no-wrap">
                    {{ getShipmentStatus(item.Contract.shipmentstatus_id, item.Contract.shipmentstatus_text) }}
                </span>
            </template>
            <template v-slot:item.Contract.etsdate="{ item }">
                {{ item.Contract.etsdate != '0000-00-00' ? formatDate(item.Contract.etsdate) : '' }}
            </template>
            <template v-slot:item.Contract.etddate="{ item }">
                {{ item.Contract.etddate != '0000-00-00' ? formatDate(item.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Contract.etadate="{ item }">
                {{ item.Contract.etadate != '0000-00-00' ? formatDate(item.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.Contract.lcno="{ item }">
                <div class="d-flex flex-column" v-if="item.Contract.lcno !== null && item.Contract.lcno != ''">
                    <span class="text-no-wrap">{{ $t('message.no') + ": " + ((item.Contract.lcno !== null && item.Contract.lcno != '') ? item.Contract.lcno : '') }}</span>
                    <span class="text-no-wrap">{{ "S/D: " + ((item.Contract.lclsdate !== null && item.Contract.lclsdate != '0000-00-00') ? formatDate(item.Contract.lclsdate) : '') }}</span>
                    <span class="text-no-wrap">{{ "E/D: " + ((item.Contract.lcedate !== null && item.Contract.lcedate != '0000-00-00') ? formatDate(item.Contract.lcedate) : '') }}</span>
                </div>
            </template>
            <template v-slot:item.Customerpayment.status="{ item }">
                <span :class="item.Customerpayment.status && item.Customerpayment.status.length > 10 ? 'font-xs' : 'font-sm'" v-if="[3,6].includes(item.Contract.salestype_id) == false">{{ item.Customerpayment.status }}</span>
            </template>
            <template v-slot:item.Supplierpayment.status="{ item }">
                <span :class="item.Supplierpayment.status && item.Supplierpayment.status.length > 10 ? 'font-xs' : 'font-sm'">{{ item.Supplierpayment.status }}</span>
            </template>
            <template v-slot:item.Contract.Shipment.internalremark="{ item }">
                <span :class="item.Contract.Shipment.internalremark && item.Contract.Shipment.internalremark.length > 10 ? 'font-xs' : 'font-sm'">{{ item.Contract.Shipment.internalremark }}</span>
            </template>
            <template v-slot:item.Contract.Debitnote.title="{ item }">
                <div class="text-no-wrap" v-if="item.Contract.Debitnote.title != null">{{ item.Contract.Debitnote.title }}</div>
            </template>
            <template v-slot:item.Contract.margin="{ item }">
                <div class="flex-row text-left">
                    <span class="pr-1">{{ currency(item.Contract.currency_id) }}</span>
                    <span>{{ formatThisNumber(item.Contract.margin) }}</span>
                </div>
            </template>
            <template v-slot:item.Contract.margin_pct="{ item }">
                <span v-if="[1,5].includes(item.Contract.salestype_id)">{{ formatThisNumber(item.Contract.material_margin_pct,'0,0.0') }}</span>
                <span v-else>{{ formatThisNumber(item.Contract.margin_pct,'0,0.0') }}</span>
            </template>
            <template v-slot:item.Contract.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="reopenContract(item.Contract.id)" v-if="$can('reopen','Contract') && item.Contract.salestatus_id == 2 && item.Contract.issplitcomplete != 1"><v-icon color="black lighten-1" small class="mr-1">mdi-lock-open-outline</v-icon>{{ $t('message.reopenContract') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateContract(item.Contract.id)" v-if="$can('update','Contract')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateContract') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Contract.Purchaseorder.id)" v-if="[1,2].includes(item.Contract.salestatus_id) && item.Contract.Purchaseorder.id != null && $can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateShipment(item.Contract.id)" v-if="[1,2].includes(item.Contract.salestatus_id) && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>

                        <!--added 2021-08-06-->
                        <v-list-item class="font-sm" @click="updateShippingInstruction(item.Contract.Sda.id)" v-if="item.Contract.Sda.id != null && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShippingInstruction') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePackingList(item.Contract.Pldo.id)" v-if="item.Contract.Pldo.id != null && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateInvoice(item.Contract.Invoice.id)" v-if="[1,5].includes(item.Contract.salestype_id) && item.Contract.Invoice.id != null && $can('update','Invoice')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateDebitNote(item.Contract.Debitnote.id)" v-if="[2].includes(item.Contract.salestype_id) && item.Contract.Debitnote.id != null && $can('update','DebitNote')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDebitNote') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePayments(item.Contract.id)" v-if="$can('update','Payment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePayments') }}</v-list-item>
                        <!---->

                        <!--added 2022-02-08 -->
                        <v-list-item class="font-sm" @click="restoreContract(item.Contract.id)" v-if="item.Contract.salestatus_id == 10 && $can('restore','Contract')"><v-icon color="green darken-2" small class="mr-1">fas fa-recycle</v-icon>{{ $t('message.restoreContract') }}</v-list-item>
                        <!---->

                        <!--added 2021-08-31-->
                        <v-list-item class="font-sm" @click="updateGrossMargin(item.Contract.id)" v-if="false"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateGrossMargin') }}</v-list-item>
                        <!---->

                        <v-list-item class="font-sm" @click="completeContract(item.Contract.id)" v-if="[1].includes(item.Contract.salestatus_id) && $can('update','Contract')"><v-icon color="green lighten-1" small class="mr-1">done</v-icon>{{ $t('message.markComplete') }}</v-list-item>
                        <v-list-item class="font-sm" @click="cancelContract(item.Contract.id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))" v-if="[1,2].includes(item.Contract.salestatus_id) && $can('delete','Contract')"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelContract') }}</v-list-item>
                        <PrintContractButton
                            :update-mode="true"
                            :list-mode="true"
                            :contract-id="item.Contract.id"
                            :contract-item="item"
                            :mergeable-docs="item.Contract.mergeableDocs"
                            :sales-list="false"
                            v-if="[1,2,5].includes(item.Contract.salestype_id)"
                        />
                        <v-list-item class="font-sm" @click="viewSignedPdf(item)" v-if="[1,5].includes(item.Contract.salestype_id) && (item.Contract.TmsSignedOrder.location != null || item.Contract.SignedOrder.location != null)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedContract') }}</v-list-item>
                        <PrintPurchaseOrderButton
                            :update-mode="true"
                            :list-mode="true"
                            :purchase-order-id="item.Contract.Purchaseorder.id"
                            v-if="[1,3,5,6].includes(item.Contract.salestype_id)  && item.Contract.Purchaseorder.id != null"
                        />
                        <v-list-item class="font-sm" @click="viewSignedPoPdf(item)" v-if="[1,3,5,6].includes(item.Contract.salestype_id) && item.Contract.Purchaseorder.id != null && (item.Contract.TmsSignedPurchaseOrder.location != null || item.Contract.SignedPurchaseOrder.location != null)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedPo') }}</v-list-item>
                        <v-list-item
                            class="font-sm"
                            @click="openShippingDocumentsDialog(item.Contract.id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Contract.salestype_id) && [1,2].includes(item.Contract.salestatus_id) && false"
                        >
                            <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                            {{ $t('message.shippingDocuments') }}
                        </v-list-item>
                        <v-list-item
                            class="font-sm"
                            @click="openShippingInfoDialog(item.Contract.id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))"
                            v-if="[1,2].includes(item.Contract.salestatus_id) && item.Contract.issplitcomplete != 1 && [1,2].includes(item.Contract.salestatus_id) && false"
                        >
                            <v-icon small color="blue lighten-1" class="mr-2">mdi-ferry</v-icon>
                            {{ $t('message.shippingInfo') }}
                        </v-list-item>

                        <!--added 2021-08-27-->
                        <v-list-item
                            class="font-sm"
                            @click="openShippingInfoAndDocumentsDialog(item.Contract.id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Contract.salestype_id) && [1,2].includes(item.Contract.salestatus_id)"
                        >
                            <v-icon small color="blue lighten-1" class="mr-2">mdi-ferry</v-icon>
                            {{ $t('message.shippingInfoAndDocuments') }}
                        </v-list-item>
                        <!---->

                        <!--added 2021-08-13-->
                        <v-list-item
                            class="font-sm"
                            @click="openNonShippingDocumentsDialog(item.Contract.id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Contract.salestype_id)"
                        >
                            <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                            {{ $t('message.otherContractDocuments') }}
                        </v-list-item>
                        <!---->

                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
        <ShippingDocumentsList
            :main-document="shippingDocumentsTitle"
            :open-dialog="shippingDocumentsDialog"
            :shipping-documents="shippingDocuments"
            @dialog-closed="closeShippingDocumentsDialog()"
        />
        <ShippingInfoSummary
            :main-document="shippingInfoTitle"
            :open-dialog="shippingInfoDialog"
            :shipment-id="shippingInfoId"
            @dialog-closed="closeShippingInfoDialog()"
        />
        <ShippingInfoAndDocumentsV4
            :main-document="shippingInfoTitle"
            :open-dialog="shippingInfoAndDocumentsDialog"
            :shipping-documents="shippingDocuments"
            :eutr-documents="eutrDocuments"
            :shipment-id="shippingInfoId"
            @dialog-closed="closeShippingInfoAndDocumentsDialog()"
        />
        <NonShippingDocumentsList
            :main-document="nonShippingDocumentsTitle"
            :open-dialog="nonShippingDocumentsDialog"
            :non-shipping-documents="nonShippingDocuments"
            @dialog-closed="closeNonShippingDocumentsDialog()"
        />
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {formatDate, log} from "Helpers/helpers";
    import {numberFormat} from "../../helpers/helpers";
    import {api} from "Api";
    import { v4 as uuidv4 } from 'uuid';

    const NonShippingDocumentsList = () => import("Components/Appic/NonShippingDocumentsList");
    const ShippingDocumentsList = () => import("Components/Appic/ShippingDocumentsList");
    // const ShippingInfoAndDocuments = () => import("Components/Appic/ShippingInfoAndDocuments");
    const ShippingInfoSummary = () => import("Components/Appic/ShippingInfoSummary");
    const SimpleAlert = () => import("./SimpleAlert");
    const ExportTableContract = () => import("Components/Appic/ExportTableContract");
    const PrintPurchaseOrderButton = () => import("Components/Appic/Printing/PrintPurchaseOrderButton");
    const PrintContractButton = () => import("Components/Appic/Printing/PrintContractButton");
    // const ShippingInfoAndDocumentsV3 = () => import("Components/Appic/ShippingInfoAndDocumentsV3");
    const ShippingInfoAndDocumentsV4 = () => import("Components/Appic/ShippingInfoAndDocumentsV4");

    export default {
        name: "ContractsListingNew",
        components: {
            ExportTableContract,
            NonShippingDocumentsList,
            PrintContractButton,
            PrintPurchaseOrderButton,
            ShippingDocumentsList,
            // ShippingInfoAndDocuments,
            // ShippingInfoAndDocumentsV3,
            ShippingInfoAndDocumentsV4,
            ShippingInfoSummary,
            SimpleAlert
        },
        data() {
            return {
                currentFilter: null,
                currentSearch: null,
                dateFilterMenus: {
                    osd: false,
                    ets: false,
                    etd: false,
                    eta: false
                },
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                eutrDocuments: [],
                filterItems: {
                    buyer: [],
                    destination: [],
                    salesContact: [],
                    status: [],
                    supplier: []
                },
                filterValues: {
                    buyer: null,
                    destination: null,
                    status: 1,
                    salesContact: null,
                    supplier: null
                },
                hideDefaultFooter: false,
                loading: {
                    filter: {
                        buyers: false,
                        destinations: false,
                        payments: false,
                        statuses: false,
                        suppliers: false
                    },
                    filterItems: {
                        buyer: null,
                        destination: null,
                        status: null,
                        salesContact: null,
                        supplier: null,
                    },
                    search: false,
                    contracts: false
                },
                nonShippingDocuments: [],
                nonShippingDocumentsDialog: false,
                nonShippingDocumentsTitle: '',
                searching: false,
                searchField: 'Contract.title', //default search field
                searchTerm: null,
                searchValues: {
                    title: null,
                    osd: [],
                    ets: [],
                    etd: [],
                    eta: []
                },
                shippingDocuments: [],
                shippingDocumentsDialog: false,
                shippingDocumentsTitle: '',
                shippingInfoDialog: false,
                shippingInfoAndDocumentsDialog: false,
                shippingInfoId: null,
                shippingInfoTitle: '',
                sortFieldName: null,
                tableOptions: {},
            }
        },
        computed: {
            ...mapMultiRowFields('contract',{
                Contracts: 'current.Contracts'
            }),
            ...mapFields('contract',{
                exportConditions: 'exportConditions',
                totalContracts: 'current.totalContracts'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('contract',{
                contractBuyers: 'contractBuyers',
                contractDestinations: 'contractDestinations',
                contractPaymentStatuses: 'contractPaymentStatuses',
                contractStatuses: 'contractStatuses',
                contractSuppliers: 'contractSuppliers',
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('document', {
                allDocuments: 'allDocuments',
            }),
            ...mapGetters('grade', {
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('size', {
                allSizes: 'allSizes',
                findSizeById: 'findSizeById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters([
                'containerSizes',
                'currencies',
                'shipmentStatuses'
            ]),
            console: () => console,
            defaultItemsPerPage() {
                if(window.screen.height >= 800) return 15
                return 10
            },
            formatDate: () => formatDate,
            headers() {
                return [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Contract.id',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'Contract.contractdate',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 2,
                        text: this.$t('message.no'),
                        value: 'Contract.title',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 3,
                        text: this.$t('message.status'),
                        value: 'Contract.salestatus_id',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Contract.Customer.title',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 5,
                        text: this.$t('message.sc'),
                        value: 'Contract.Salesperson.abbreviation',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 6,
                        text: this.$t('message.spl'),
                        value: 'Contract.Supplier.code',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 7,
                        text: this.$t('message.po'),
                        value: 'Contract.po',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 8,
                        text: this.$t('message.supPi'),
                        value: 'Contract.supplier_pi_no',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 9,
                        text: this.$t('message.species'),
                        value: 'Contract.species',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 10,
                        text: this.$t('message.grade'),
                        value: 'Contract.grade',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 11,
                        text: this.$t('message.size'),
                        value: 'Contract.size',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 12,
                        text: this.$t('message.container'),
                        value: 'Contract.containersize_id',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 13,
                        text: this.$t('message.cost'),
                        value: 'Contract.cost',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 14,
                        text: this.$t('message.value'),
                        value: 'Contract.amount',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 15,
                        text: this.$t('message.osd'),
                        value: 'Contract.shipweekdate',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 16,
                        text: this.$t('message.shipmentStatus'),
                        value: 'Contract.shipmentstatus_id',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 17,
                        text: this.$t('message.ets'),
                        value: 'Contract.etsdate',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 18,
                        text: this.$t('message.etd'),
                        value: 'Contract.etddate',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 19,
                        text: this.$t('message.eta'),
                        value: 'Contract.etadate',
                        sortable: true,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 20,
                        text: this.$t('message.lc'),
                        value: 'Contract.lcno',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 21,
                        text: this.$t('message.paymentBuyer'),
                        value: 'Customerpayment.status',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 22,
                        text: this.$t('message.paymentSupplier'),
                        value: 'Supplierpayment.status',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 23,
                        text: this.$t('message.remarks'),
                        value: 'Contract.Shipment.internalremark',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 24,
                        text: this.$t('message.dn'),
                        value: 'Contract.Debitnote.title',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 25,
                        text: this.$t('message.margin'),
                        value: 'Contract.margin',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 26,
                        text: '%',
                        value: 'Contract.margin_pct',
                        sortable: false,
                        searchable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    }
                ]
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            eutrFiles() {
                let files = [];
                files = this.allDocuments.ShippingEutrDocuments
                return files
            },
        },
        methods: {
            ...mapActions('contract',{
                cancelContractById: 'cancelContractById',
                completeContractById: 'completeContractById',
                getAllContracts: 'getAllContracts',
                // getAllShipmentDestinations: 'getAllShipmentDestinations',
                getAllContractBuyers: 'getAllContractBuyers',
                getAllContractDestinations: 'getAllContractDestinations',
                getAllContractPaymentStatuses: 'getAllContractPaymentStatuses',
                getAllContractStatuses: 'getAllContractStatuses',
                // getAllContractSalesContacts: 'getAllContractSalesContacts',
                getAllContractSuppliers: 'getAllContractSuppliers',
                reopenContractById: 'reopenContractById',
                restoreContractById: 'restoreContractById',
                searchAllContracts: 'searchAllContracts'
            }),
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('document',{
                searchAssetDocument: 'searchAssetDocument'
            }),
            ...mapActions('size', {
                getAllSizes: 'getAllSizes'
            }),
            async cancelContract(val, title) {
                if(await this.$root.$confirm(this.$t('message.cancelContract') + ' ' +  title, this.$t('message.confirmations.continueContractCancelAction'), {color: 'orange'})){
                    this.cancelContractById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.contractDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            // this.loadContracts()
                            if(this.filterValues.status == 1){
                                this.filterContracts()
                            } else {
                                this.loadContracts()
                            }
                        } else {
                            this.$toast.error(this.$t('message.errors.contractNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            checkEutrEudrDocument(docTypeId) {
                const file = this.eutrFiles.find(ef => ef.Document.doctype_id == docTypeId)
                if(file) return true
                return false
            },
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.destination = null
                this.filterValues.status = 1
                this.filterValues.salesContact = null
                this.filterValues.supplier = null
                this.searching = false
                this.searchValues.title = null
                this.searchValues.osd = []
                this.searchValues.ets = []
                this.searchValues.etd = []
                this.searchValues.eta = []
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Contract.contractdate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterContracts()
            },
            closeNonShippingDocumentsDialog () {
                this.nonShippingDocumentsDialog = false
            },
            closeShippingDocumentsDialog () {
                this.shippingDocumentsDialog = false
            },
            closeShippingInfoDialog() {
                this.shippingInfoDialog = false
                this.shippingInfoId = null
                this.shippingInfoTitle = ''
            },
            closeShippingInfoAndDocumentsDialog() {
                this.shippingInfoAndDocumentsDialog = false
                this.shippingInfoId = null
                this.shippingInfoTitle = ''
                this.shippingDocuments = []
                this.eutrDocuments = []
            },
            async completeContract(val) {
                if (await this.$root.$confirm(this.$t('message.markComplete'), this.$t('message.confirmations.continueContractMarkCompleteAction'), {color: 'orange'})) {
                    this.completeContractById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.contractCompleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadContracts()
                        } else {
                            this.$toast.error(this.$t('message.errors.contractNotCompleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            closeDateFilterMenu(filter) {
                //check if values are valid. don't allow reverse ranges where d1 > d2
                let d1 = new Date(this.searchValues[filter][0])
                let d2 = new Date(this.searchValues[filter][1])
                if(d1 > d2 && this.searchValues[filter].length == 2){
                    let newDate1 = this.searchValues[filter][1]
                    let newDate2 = this.searchValues[filter][0]
                    this.searchValues[filter] = [newDate1, newDate2]
                }
                if(this.searchValues[filter].length == 2) this.dateFilterMenus[filter] = false
            },
            computedFilterDates(date){
                let dates = ''
                if(this.searchValues[date] != null && this.searchValues[date][0] != null) dates = dates + formatDate(this.searchValues[date][0])
                if(this.searchValues[date] != null && this.searchValues[date][1] != null) dates = dates + " ~ " + formatDate(this.searchValues[date][1])
                return dates
            },
            contractGrades (ids){
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let grade = this.findGradeById(id)
                        if (grade) {
                            array.push(grade.Grade.title)
                        }
                    })
                }
                return array
            },
            contractSizes (ids) {
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let size = this.findSizeById(id)
                        if (size) {
                            array.push(size.Size.title)
                        }
                    })
                }
                return array
            },
            contractSpecies (ids) {
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let species = this.findSpeciesById(id)
                        if (species) {
                            array.push(species.Species.title)
                        }
                    })
                }
                return array
            },
            currency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterContracts() {
                this.searchValues.title = null
                this.searchValues.osd = []
                this.searchValues.ets = []
                this.searchValues.etd = []
                this.searchValues.eta = []
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if(this.filterValues.buyer != null) conditions.push({field: 'Contract.customer_id', value: this.filterValues.buyer})
                    if(this.filterValues.destination != null) conditions.push({field: 'Contract.destinatione_id', value: this.filterValues.destination})
                    if(this.filterValues.status != null) conditions.push({field: 'Contract.status_id', value: this.filterValues.status})
                    if(this.filterValues.supplier != null) conditions.push({field: 'Contract.supplier_id', value: this.filterValues.supplier})
                    if(this.filterValues.salesContact != null) conditions.push({field: 'Contract.salescontact_id', value: this.filterValues.salesContact})

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if(_.isEqual(this.currentFilter,this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Contract.contractdate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Contract.contractdate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        // if(this.tableOptions.sortBy[0] == 'Contract.contractdate') {
                        //     this.tableOptions['sortDesc'] = [true]
                        // }
                    }
                    if(this.tableOptions.sortBy[0] == 'Contract.contractdate' && this.tableOptions.sortDesc.length == 0){
                        // this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.contracts = true;
                    this.searchAllContracts(payload)
                        .then(() => {
                            this.loading.contracts = false;
                        })
                        .catch(()=>{
                            this.loading.contracts = false;
                        })
                        .finally(()=>{
                            this.loading.contracts = false;
                        })
                } else {
                    this.loadContracts()
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getContainerSize (containersize_id) {
                let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == containersize_id)
                if( container ) {
                    return container?.Containersize.title
                } else {
                    return null
                }
            },
            getShipmentStatus (id, text) {
                if(id) {
                    let status = this.shipmentStatuses.find(d => d.Shipmentstatus.id == id)
                    if(status?.Shipmentstatus?.hastext == 1){
                        if(id == 1){
                            if(parseInt(text) == text){
                                return 'ETD: WK ' + text
                            } else {
                                return text
                            }
                        } else {
                            return text
                        }
                    } else {
                        return status?.Shipmentstatus?.title
                    }
                }
                return null
            },
            getStatus (contract) {
                let status = null
                if(contract.Contract.salestatus_id == 1 && [1,2,5].includes(contract.Contract.salestype_id) && (contract.Contract.issigned == 0 || contract.Contract.issigned == null)){
                    status = 'contractUnsigned'
                } else if(contract.Contract.salestatus_id == 1 && [1,2,5].includes(contract.Contract.salestype_id) && (contract.Contract.isposigned == 0 || contract.Contract.isposigned == null)) {
                    status = 'poUnsigned'
                } else if(contract.Contract.salestatus_id == 1 && [3].includes(contract.Contract.salestype_id) && (contract.Contract.isposigned == 0 || contract.Contract.isposigned == null)){
                    status = 'whPoUnsigned'
                }
                return status
            },
            loadContracts () {
                this.hideDefaultFooter = false
                this.loading.contracts = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                let payload = {
                    tableOptions: this.tableOptions
                }
                this.getAllContracts(payload)
                    .then(()=>{
                        this.loading.contracts = false;
                    })
                    .catch(()=>{
                        this.loading.contracts = false;
                    })
                    .finally(()=>{
                        this.loading.contracts = false;
                    })
            },
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/contracts/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data

                                    //add All to some options
                                    if(filter == 'status'){
                                        this.filterItems[filter].push({Status: {id: 0, title: 'all' }})
                                    }

                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
            openNonShippingDocumentsDialog (documentId, documentTitle) {
                this.loading.contracts = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/other-contract-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.nonShippingDocuments = []
                        this.nonShippingDocumentsTitle = ''
                        this.nonShippingDocuments = data
                        this.nonShippingDocumentsTitle = documentTitle
                    })
                    .catch(() => {
                        this.loading.contracts = false;
                    })
                    .then(() => {
                        let self = this
                        setTimeout(function(){
                            self.loading.contracts = false;
                            self.nonShippingDocumentsDialog = true;
                        }, 100)
                    })
            },
            openShippingDocumentsDialog (documentId, documentTitle) {
                this.loading.contracts = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/shipping-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.shippingDocuments = []
                        this.shippingDocumentsTitle = ''
                        this.shippingDocuments = data
                        this.shippingDocumentsTitle = documentTitle
                    })
                    .catch(() => {
                        this.loading.contracts = false;
                    })
                    .then(() => {
                        let self = this
                        setTimeout(function(){
                            self.loading.contracts = false;
                            self.shippingDocumentsDialog = true;
                        }, 100)
                    })
            },
            openShippingInfoDialog( documentId, documentTitle ) {
                this.shippingInfoTitle = documentTitle
                this.shippingInfoId = documentId
                this.shippingInfoDialog = true
            },
            openShippingInfoAndDocumentsDialog(documentId, documentTitle) {
                this.loading.contracts = true
                this.shippingDocuments = []
                this.eutrDocuments = []
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/shipping-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.loading.contracts = false;
                        data.forEach( d => {
                            if(this.checkEutrEudrDocument(d.Document.doctype_id)){
                                this.eutrDocuments.push(d)
                            } else {
                                this.shippingDocuments.push(d)
                            }
                        })
                        this.shippingInfoTitle = documentTitle
                        this.shippingInfoId = documentId
                        this.shippingInfoAndDocumentsDialog = true
                    })
                    .catch(() => {
                        this.loading.contracts = false;
                    })
            },
            resetSearch (filter = null) {
                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.status = 1
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                switch(filter){
                    case 'eta':
                    case 'etd':
                    case 'ets':
                    case 'osd':
                        this.searchValues[filter] = []
                        break
                }

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []

                this.filterContracts()
            },
            async reopenContract(documentId) {
                if (await this.$root.$confirm(this.$t('message.reopenContract'), this.$t('message.confirmations.continueContractReopenAction'), {color: 'orange'})) {
                    this.reopenContractById(documentId)
                        .then((status) => {
                            if(status == 'done'){
                                this.$toast.success(this.$t('message.successes.contractReopened'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadContracts()
                            } else {
                                this.$toast.error(this.$t('message.errors.contractNotReopened'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.contractNotReopened'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            async restoreContract(documentId) {
                if (await this.$root.$confirm(this.$t('message.restoreContract'), this.$t('message.confirmations.continueContractRestoreAction'), {color: 'orange'})) {
                    this.restoreContractById(documentId)
                        .then((status) => {
                            if(status == 'done'){
                                this.$toast.success(this.$t('message.successes.contractRestored'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadContracts()
                            } else {
                                this.$toast.error(this.$t('message.errors.contractNotRestored'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.contractNotRestored'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            searchContracts (field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
                } else {
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.destination = null
                    this.filterValues.status = null
                    this.filterValues.salesContact = null
                    this.filterValues.supplier = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            if(['osd','ets','etd','eta'].includes(key)){
                                this.searchValues[key] = []
                            } else {
                                this.searchValues[key] = null
                            }
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'title':
                            fieldName = 'Contract.title'
                            sortFieldName = 'Contract.contractdate'
                            break
                        case 'osd':
                            fieldName = 'Contract.shipweekdate'
                            sortFieldName = 'Contract.shipweekdate'
                            break
                        case 'ets':
                            fieldName = 'Contract.etsdate'
                            sortFieldName = 'Contract.etsdate'
                            break
                        case 'etd':
                            fieldName = 'Contract.etddate'
                            sortFieldName = 'Contract.etddate'
                            break
                        case 'eta':
                            fieldName = 'Contract.etadate'
                            sortFieldName = 'Contract.etadate'
                            break
                    }

                    this.loading.contracts = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: field == 'title' ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = field == 'title' ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }
                    this.searchAllContracts(payload)
                        .then(() => {
                            this.loading.contracts = false;
                        })
                        .catch(()=>{
                            this.loading.contracts = false;
                        })
                        .finally(()=>{
                            this.loading.contracts = false;
                        })
                }
            },
            updateContract (val) {
                let tab = window.open('/v1/contracts/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.title != null) field = 'title'
                    if(this.searchValues.osd.length > 0) field = 'osd'
                    if(this.searchValues.ets.length > 0) field = 'ets'
                    if(this.searchValues.eta.length > 0) field = 'eta'
                    if(this.searchValues.etd.length > 0) field = 'etd'
                    this.searchContracts(field)
                } else {
                    this.filterContracts()
                }
            },
            updateGrossMargin(val) {
                let tab = window.open('/v1/gross-margins/add/invoice/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateInvoice(val) {
                let tab = window.open('/v1/invoices/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateDebitNote(val) {
                let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePackingList(val) {
                let tab = window.open('/v1/packinglists/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePayments(val) {
                let tab = window.open('/v1/payments/update/contracts/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePurchaseOrder(val) {
                let tab = window.open('/v1/purchaseorders/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShippingInstruction(val){
                let tab = window.open('/v1/sdas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShipment (val) {
                let tab = window.open('/v1/shipments/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf (contract) {
                let document = encodeURIComponent(contract.Contract.version != null ? [contract.Contract.title, contract.Contract.revision_no , contract.Contract.partial_no].filter(Boolean).join('-') : contract.Contract.title)
                if([1,5].includes(contract.Contract.salestype_id)) {
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + document
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/contracts/print/'
                        + contract.Contract.id
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                        // + '&fileName=' + contract.Contract.title + '.pdf'
                        , "_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else if(contract.Contract.salestype_id == 2){
                    if(contract.Contract.version != null){
                        if (contract.Contract.SignedOrder.location != null) {
                            let tab = window.open(contract.Contract.SignedOrder.location, "_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            if(contract.Contract.TmsSignedOrder.location != null){
                                let tab = window.open(contract.Contract.TmsSignedOrder.location,"_blank")
                                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                                this.openedTabs.push(tab.name)
                            } else {
                                this.dialogs.error = true
                                this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                            }
                        }
                    } else {
                        if(contract.Contract.TmsSignedOrder.location != null){
                            let tab = window.open(contract.Contract.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.dialogs.error = true
                            this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                        }
                    }
                } else {
                    return false
                }
            },
            viewSignedPdf (contract) {
                if(contract.Contract.version != null && contract.Contract.SignedOrder.location != null){
                    let tab = window.open(contract.Contract.SignedOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else {
                    let tab = window.open(contract.Contract.TmsSignedOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            },
            viewPoPdf ( contract ) {
                let document = contract.Contract.version != null ? [contract.Contract.title, contract.Contract.revision_no , contract.Contract.partial_no].filter(Boolean).join('-') : contract.Contract.title
                document = 'PO ' + document
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/purchaseorders/print/'
                    + contract.Contract.Purchaseorder.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewSignedPoPdf ( contract ) {
                if(contract.Contract.version != null && contract.Contract.SignedPurchaseOrder.location != null){
                    let tab = window.open(contract.Contract.SignedPurchaseOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else {
                    let tab = window.open(contract.Contract.TmsSignedPurchaseOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            }
            // viewPdf (contract_id, salestype_id) {
            //     if([1,3,5,6].includes(salestype_id)) {
            //         let user = JSON.parse(localStorage.getItem('user'))
            //         window.open(
            //             process.env.VUE_APP_PDF_SERVER
            //             + '/api/render?url='
            //             + process.env.VUE_APP_BASE_URL
            //             + '/v1/contracts/print/'
            //             + contract_id
            //             + '/'
            //             + user.apiToken
            //             + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
            //             , "_blank");
            //     } else if(salestype_id == 2){
            //         let conditions = [
            //             {field: 'asset', value: 'contract'},
            //             {field: 'asset_id', value: contract_id},
            //             {field: 'doctype_id', value: 91}
            //         ]
            //         this.searchAssetDocument(conditions)
            //             .then((response)=>{
            //                 if(response.status == 'success' && response.data.length > 0){
            //                     let location = response.data[0]?.AssetDocument.location
            //                     if(location.trim().length > 0){
            //                         window.open(location,"_blank")
            //                     } else {
            //                         this.dialogs.error = true
            //                         this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
            //                     }
            //                 } else {
            //                     this.dialogs.error = true
            //                     this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
            //                 }
            //             })
            //             .catch(()=>{
            //                 this.dialogs.error = true
            //                 this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
            //             })
            //             .finally(()=>{
            //
            //             })
            //     } else {
            //         return false
            //     }
            // }
        },
        created () {
            this.getAllSizes()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            this.searchValues.title = null
            this.searchValues.osd = []
            this.searchValues.ets = []
            this.searchValues.etd = []
            this.searchValues.eta = []
            this.filterValues.buyer = null
            this.filterValues.destination = null
            this.filterValues.status = 1
            this.filterValues.salesContact = null
            this.filterValues.supplier = null

            this.tableOptions.sortDesc = [true] //added 2022-07-28
            this.eutrDocuments = []
            this.shippingDocuments = []

            if(this.filterValues.status == 1){
                this.filterContracts()
            } else {
                this.loadContracts()
            }

            if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
            if(this.filterItems.destination.length == 0) this.loadFilterItems('destination')
            if(this.filterItems.status.length == 0) this.loadFilterItems('status')
            if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
            if(this.filterItems.salesContact.length == 0) this.loadFilterItems('salesContact')
        },
        mounted() {
            this.getAllSizes()
        }
    }
</script>

<style>
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-130 {
    width: 130px !important;
    max-width: 130px !important;
}
.filter-width-140 {
    width: 135px !important;
    max-width: 135px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-170 {
    width: 170px !important;
    max-width: 170px !important;
}
.filter-width-180 {
    width: 180px !important;
    max-width: 180px !important;
}
.filter-width-185 {
    width: 185px !important;
    max-width: 185px !important;
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}

.height-10px {
    height: 10px;
}
</style>